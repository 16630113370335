<script lang="ts" setup>
import { cloneDeep } from 'lodash'
import { PropType } from 'vue'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { ISelectable } from '~/types/Selectable'
import { EmitsEnum } from '~~/src/constants/emits'

const props = defineProps({
	items: { type: Object as PropType<ISelectable<TranslationKey, any>[]>, required: true },
	getChild: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	getChildProps: { type: Function as PropType<(pos: number) => any>, default: () => undefined },
	translateCheckboxes: { type: Boolean, default: true },
})

const { items, getChild, getChildProps, translateCheckboxes } = toRefs(props)
const clone = ref(cloneDeep(items))
watch(items, () => (clone.value = cloneDeep(items.value)))

const emit = defineEmits([EmitsEnum.Change])
const onChange = (position: number, value: boolean) => {
	const result = clone.value.map((el, index) => ({ ...el, selected: index === position ? value : false }))

	// deselect the other radio buttons
	emit(EmitsEnum.Change, result)
}

const getBoxBackground = (box: ISelectable<TranslationKey, any[]>) =>
	box.disabled
		? 'odd:bg-warm-grey-50 even:bg-warm-grey-100'
		: 'odd:bg-white even:bg-warm-grey-50 hover:bg-warm-grey-100'
</script>

<template>
	<div class="flex flex-col truncate rounded-md border border-dark-blue-100">
		<template v-for="(box, index) in clone">
			<CommonRadioButton
				v-if="getChild!(index) != undefined"
				:text="box.element"
				:clicked="box.selected"
				@click="(value: boolean) => onChange(index, value)"
				:disabled="box.disabled"
				:hint="box.hint"
				class="px-4 py-2"
				:class="getBoxBackground(box)"
				:data-id="`radio-room-select-row-${index}`"
				:translate-radio="translateCheckboxes"
			>
				<component :is="getChild!(index)" v-bind="getChildProps?.(index)" />
			</CommonRadioButton>
			<CommonRadioButton
				v-else
				:text="box.element"
				:clicked="box.selected"
				@click="(value: boolean) => onChange(index, value)"
				class="px-4 py-2 odd:bg-white even:bg-warm-grey-50"
				:data-id="`radio-room-select-row-${index}`"
				:translate-radio="translateCheckboxes"
			/>
		</template>
	</div>
</template>
