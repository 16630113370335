<script lang="ts" setup>
import { EmitsEnum } from '~/constants/emits'
import { PropType } from 'vue'
import { TextSizes } from '~~/src/constants/textSizes'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TranslationKey } from '~/i18n/TranslationKeys'

const props = defineProps({
	clicked: { type: Boolean, default: false },
	text: { type: String as PropType<TranslationKey>, required: false },
	disabled: { type: Boolean, default: false },
	hint: { type: String as PropType<TranslationKey>, required: false },
	translateRadio: { type: Boolean, default: true },
})
const { clicked, text, disabled, hint, translateRadio } = toRefs(props)

const slots = useSlots()
const hasSlot = computed(() => slots.default != undefined)

const emit = defineEmits([EmitsEnum.Click])
const onClick = () => {
	if (disabled.value) {
		return
	}

	emit(EmitsEnum.Click, !clicked.value)
}

const cellClasses = computed(() => {
	let classes = clicked.value ? 'border-4 p-0.5' : 'border p-1.5'
	if (disabled.value) {
		return classes + ' bg-dark-blue-100 border-dark-blue-200'
	}
	classes += clicked.value ? ' border-sky-600 bg-white' : ' border-sky-300 bg-sky-100'

	return classes
})

const showHint = ref(false)
</script>

<template>
	<div>
		<div
			class="flex flex-row items-center justify-between gap-2"
			:class="disabled ? '' : 'cursor-pointer'"
			@click="onClick"
		>
			<div class="flex items-center gap-2 py-2">
				<div class="h-3.5 w-3.5 rounded-full" :class="cellClasses"></div>
				<CommonText
					v-if="text != undefined"
					:text="text"
					:text-size="TextSizes.BODY_REGULAR"
					class="line-clamp-2 whitespace-pre-line"
					:translate="translateRadio"
				/>
			</div>
			<CommonTooltip v-if="hint != undefined" :text="hint" :show="showHint" :width="'w-32'">
				<CommonIcon
					:icon-name="SpSvg.BasicHelp"
					:icon-size="IconSizes.S"
					@mouseenter="showHint = true"
					@mouseleave="showHint = false"
				/>
			</CommonTooltip>
		</div>
		<AnimationCollapse>
			<div v-if="hasSlot && clicked" class="truncate bg-dark-blue-100 p-3">
				<slot></slot>
			</div>
		</AnimationCollapse>
	</div>
</template>
